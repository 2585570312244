/* Generated file */

import { IValueAccessor } from '../../utils/ValueAccessor';
import { ValueAccessor } from '../../utils/ValueAccessor';
import { Logement } from './Logement';
import { EtatAvancementProjet } from './EtatAvancementProjet';
import { Reservataire } from './Reservataire';
import { Financement } from './Financement';
import { Label } from './Label';
import { Caracteristique } from './Caracteristique';
import { Nature } from './Nature';
import { TypeLogement } from './TypeLogement';
import { Chauffages } from './Chauffages';
import { DiagnosticReponse } from './DiagnosticReponse';

export abstract class LogementAccessorBase {
	readonly id: IValueAccessor<number> | undefined;
	readonly creationDate: IValueAccessor<Date> | undefined;
	readonly lastModificationDate: IValueAccessor<Date> | undefined;
	readonly complet: IValueAccessor<boolean> | undefined;
	readonly comment: IValueAccessor<string | undefined> | undefined;
	readonly version: IValueAccessor<number> | undefined;
	readonly nomProgramme: IValueAccessor<string | undefined> | undefined;
	readonly anneeLivraison: IValueAccessor<number | undefined> | undefined;
	readonly anneeDesignation: IValueAccessor<number | undefined> | undefined;
	readonly rehabilitation: IValueAccessor<boolean | undefined> | undefined;
	readonly logementNeuf: IValueAccessor<boolean | undefined> | undefined;
	readonly etatAvancementProjet: IValueAccessor<EtatAvancementProjet> | undefined;
	readonly suiviParArchitecte: IValueAccessor<boolean | undefined> | undefined;
	readonly numBatiment: IValueAccessor<string | undefined> | undefined;
	readonly numLogement: IValueAccessor<string | undefined> | undefined;
	readonly numRpls: IValueAccessor<string | undefined> | undefined;
	readonly reservataire: IValueAccessor<Reservataire> | undefined;
	readonly financement: IValueAccessor<Financement> | undefined;
	readonly adresse: IValueAccessor<string | undefined> | undefined;
	readonly complementAdresse: IValueAccessor<string | undefined> | undefined;
	readonly codePostal: IValueAccessor<string | undefined> | undefined;
	readonly arrondissement: IValueAccessor<number> | undefined;
	readonly preconisationHanditoit: IValueAccessor<boolean | undefined> | undefined;
	readonly reglementationPMR: IValueAccessor<boolean | undefined> | undefined;
	readonly label: IValueAccessor<Label> | undefined;
	readonly labelAutre: IValueAccessor<string | undefined> | undefined;
	readonly caracteristique: IValueAccessor<Caracteristique> | undefined;
	readonly nature: IValueAccessor<Nature> | undefined;
	readonly type: IValueAccessor<TypeLogement> | undefined;
	readonly niveau: IValueAccessor<number | undefined> | undefined;
	readonly surface: IValueAccessor<string | undefined> | undefined;
	readonly chauffage: IValueAccessor<Chauffages> | undefined;
	readonly jardin: IValueAccessor<boolean> | undefined;
	readonly balcon: IValueAccessor<boolean> | undefined;
	readonly terrasse: IValueAccessor<boolean> | undefined;
	readonly loggia: IValueAccessor<boolean> | undefined;
	readonly garage: IValueAccessor<boolean> | undefined;
	readonly parking: IValueAccessor<boolean> | undefined;
	readonly prixParking: IValueAccessor<string | undefined> | undefined;
	readonly loyerHC: IValueAccessor<number | undefined> | undefined;
	readonly charges: IValueAccessor<number | undefined> | undefined;
	readonly chemExtConformeReglementation: IValueAccessor<DiagnosticReponse | undefined> | undefined;
	readonly chemExtSansMarchesNiRessauts: IValueAccessor<DiagnosticReponse | undefined> | undefined;
	readonly chemExtRessaut: IValueAccessor<DiagnosticReponse | undefined> | undefined;
	readonly chemExtPente: IValueAccessor<DiagnosticReponse | undefined> | undefined;
	readonly chemExtPalier: IValueAccessor<DiagnosticReponse | undefined> | undefined;
	readonly chemExtRampe: IValueAccessor<DiagnosticReponse | undefined> | undefined;
	readonly chemExtBordure: IValueAccessor<DiagnosticReponse | undefined> | undefined;
	readonly chemExtMainCourante: IValueAccessor<DiagnosticReponse | undefined> | undefined;
	readonly chemExtLargeurSup1m20: IValueAccessor<DiagnosticReponse | undefined> | undefined;
	readonly chemExtLargeurSup1m60: IValueAccessor<DiagnosticReponse | undefined> | undefined;
	readonly chemExtSolNonMeuble: IValueAccessor<DiagnosticReponse | undefined> | undefined;
	readonly chemExtTrou: IValueAccessor<DiagnosticReponse | undefined> | undefined;
	readonly chemExtObstacle: IValueAccessor<DiagnosticReponse | undefined> | undefined;
	readonly chemExtInfo: IValueAccessor<string | undefined> | undefined;
	readonly statioDedie: IValueAccessor<DiagnosticReponse | undefined> | undefined;
	readonly statioProxi: IValueAccessor<DiagnosticReponse | undefined> | undefined;
	readonly statioSousSol: IValueAccessor<DiagnosticReponse | undefined> | undefined;
	readonly statioSurface: IValueAccessor<DiagnosticReponse | undefined> | undefined;
	readonly statioVisiteur: IValueAccessor<DiagnosticReponse | undefined> | undefined;
	readonly statioSasInt: IValueAccessor<DiagnosticReponse | undefined> | undefined;
	readonly statioSasSup1m20: IValueAccessor<DiagnosticReponse | undefined> | undefined;
	readonly statioSasSup1m50: IValueAccessor<DiagnosticReponse | undefined> | undefined;
	readonly statioSasOculus: IValueAccessor<DiagnosticReponse | undefined> | undefined;
	readonly statioInfo: IValueAccessor<string | undefined> | undefined;
	readonly communPorteVoie: IValueAccessor<DiagnosticReponse | undefined> | undefined;
	readonly communPorteResidence: IValueAccessor<DiagnosticReponse | undefined> | undefined;
	readonly communPorteAuto: IValueAccessor<DiagnosticReponse | undefined> | undefined;
	readonly communPorteTele: IValueAccessor<DiagnosticReponse | undefined> | undefined;
	readonly communOuvManu: IValueAccessor<DiagnosticReponse | undefined> | undefined;
	readonly communDispoHaut: IValueAccessor<DiagnosticReponse | undefined> | undefined;
	readonly communDispoAngle: IValueAccessor<DiagnosticReponse | undefined> | undefined;
	readonly communDispoSonore: IValueAccessor<DiagnosticReponse | undefined> | undefined;
	readonly communDispoCamera: IValueAccessor<DiagnosticReponse | undefined> | undefined;
	readonly communDispoSansFil: IValueAccessor<DiagnosticReponse | undefined> | undefined;
	readonly communHallSup1m20: IValueAccessor<DiagnosticReponse | undefined> | undefined;
	readonly communHallSup1m50: IValueAccessor<DiagnosticReponse | undefined> | undefined;
	readonly communHallColectif: IValueAccessor<DiagnosticReponse | undefined> | undefined;
	readonly communHallEclairageAuto: IValueAccessor<DiagnosticReponse | undefined> | undefined;
	readonly communHallEclairage90: IValueAccessor<DiagnosticReponse | undefined> | undefined;
	readonly communHallEclairageDiode: IValueAccessor<DiagnosticReponse | undefined> | undefined;
	readonly communAscenseurBouton: IValueAccessor<DiagnosticReponse | undefined> | undefined;
	readonly communAscenseurNorme: IValueAccessor<DiagnosticReponse | undefined> | undefined;
	readonly communAscenseurHori: IValueAccessor<DiagnosticReponse | undefined> | undefined;
	readonly communInfo: IValueAccessor<string | undefined> | undefined;
	readonly generalLargeurSup90: IValueAccessor<DiagnosticReponse | undefined> | undefined;
	readonly generalLargeurSup120: IValueAccessor<DiagnosticReponse | undefined> | undefined;
	readonly generalLargeurPorte90: IValueAccessor<DiagnosticReponse | undefined> | undefined;
	readonly generalPoignetPorte: IValueAccessor<DiagnosticReponse | undefined> | undefined;
	readonly entreeLargeur: IValueAccessor<DiagnosticReponse | undefined> | undefined;
	readonly entreeSerrure: IValueAccessor<DiagnosticReponse | undefined> | undefined;
	readonly entreePoignee: IValueAccessor<DiagnosticReponse | undefined> | undefined;
	readonly entreeAuto: IValueAccessor<DiagnosticReponse | undefined> | undefined;
	readonly entreeEclairage: IValueAccessor<DiagnosticReponse | undefined> | undefined;
	readonly dispositifHauteur130: IValueAccessor<DiagnosticReponse | undefined> | undefined;
	readonly dispositifHanteur90: IValueAccessor<DiagnosticReponse | undefined> | undefined;
	readonly dispositifAngle: IValueAccessor<DiagnosticReponse | undefined> | undefined;
	readonly dispositifVoletElec: IValueAccessor<DiagnosticReponse | undefined> | undefined;
	readonly dispositifManoeuvreSecours: IValueAccessor<DiagnosticReponse | undefined> | undefined;
	readonly salleBainEspace: IValueAccessor<DiagnosticReponse | undefined> | undefined;
	readonly wcEspace: IValueAccessor<DiagnosticReponse | undefined> | undefined;
	readonly wcCloison: IValueAccessor<DiagnosticReponse | undefined> | undefined;
	readonly wcHauteur: IValueAccessor<DiagnosticReponse | undefined> | undefined;
	readonly wcSuspendu: IValueAccessor<DiagnosticReponse | undefined> | undefined;
	readonly doucheItalienne: IValueAccessor<DiagnosticReponse | undefined> | undefined;
	readonly doucheItalienneOk: IValueAccessor<DiagnosticReponse | undefined> | undefined;
	readonly doucheReceveurEncastre: IValueAccessor<DiagnosticReponse | undefined> | undefined;
	readonly doucheReceveurEncastreOk: IValueAccessor<DiagnosticReponse | undefined> | undefined;
	readonly doucheParois: IValueAccessor<DiagnosticReponse | undefined> | undefined;
	readonly lavaboAdapte: IValueAccessor<DiagnosticReponse | undefined> | undefined;
	readonly lavaboLibreSous: IValueAccessor<DiagnosticReponse | undefined> | undefined;
	readonly lavaboProfondeur: IValueAccessor<DiagnosticReponse | undefined> | undefined;
	readonly lavaboHauteur: IValueAccessor<DiagnosticReponse | undefined> | undefined;
	readonly lavaboHauteurReglable: IValueAccessor<DiagnosticReponse | undefined> | undefined;
	readonly lavaboSiphon: IValueAccessor<DiagnosticReponse | undefined> | undefined;
	readonly chambreDiametre: IValueAccessor<DiagnosticReponse | undefined> | undefined;
	readonly chambrePassage: IValueAccessor<DiagnosticReponse | undefined> | undefined;
	readonly cuisinePassage: IValueAccessor<DiagnosticReponse | undefined> | undefined;
	readonly cuisineEspace: IValueAccessor<DiagnosticReponse | undefined> | undefined;
	readonly cuisineProfondeur: IValueAccessor<DiagnosticReponse | undefined> | undefined;
	readonly cuisineSiphon: IValueAccessor<DiagnosticReponse | undefined> | undefined;
	readonly cuisineCuve: IValueAccessor<DiagnosticReponse | undefined> | undefined;
	readonly terrasseAcces: IValueAccessor<DiagnosticReponse | undefined> | undefined;
	readonly terrasseRessaut: IValueAccessor<DiagnosticReponse | undefined> | undefined;
	readonly logementInfo: IValueAccessor<string | undefined> | undefined;
	readonly communBoiteLettre: IValueAccessor<DiagnosticReponse | undefined> | undefined;
	readonly entreeEspaceManoeuvre: IValueAccessor<DiagnosticReponse | undefined> | undefined;
	readonly generalLargeurPorte80: IValueAccessor<DiagnosticReponse | undefined> | undefined;
	readonly generalEspaceManoeuvre: IValueAccessor<DiagnosticReponse | undefined> | undefined;
	readonly wcBarreAppui: IValueAccessor<DiagnosticReponse | undefined> | undefined;
	readonly wcLargeurPorte80: IValueAccessor<DiagnosticReponse | undefined> | undefined;
	readonly wcLargeurPorte90: IValueAccessor<DiagnosticReponse | undefined> | undefined;
	readonly wcPoignetPorte: IValueAccessor<DiagnosticReponse | undefined> | undefined;
	readonly wcEspaceManoeuvre: IValueAccessor<DiagnosticReponse | undefined> | undefined;
	readonly bainLargeurPorte80: IValueAccessor<DiagnosticReponse | undefined> | undefined;
	readonly bainLargeurPorte90: IValueAccessor<DiagnosticReponse | undefined> | undefined;
	readonly bainPoignetPorte: IValueAccessor<DiagnosticReponse | undefined> | undefined;
	readonly bainEspaceManoeuvre: IValueAccessor<DiagnosticReponse | undefined> | undefined;
	readonly doucheBarreAppui: IValueAccessor<DiagnosticReponse | undefined> | undefined;
	readonly doucheSiege: IValueAccessor<DiagnosticReponse | undefined> | undefined;
	readonly doucheCloisons: IValueAccessor<DiagnosticReponse | undefined> | undefined;
	readonly chambreLargeurPorte80: IValueAccessor<DiagnosticReponse | undefined> | undefined;
	readonly chambreLargeurPorte90: IValueAccessor<DiagnosticReponse | undefined> | undefined;
	readonly chambrePoignetPorte: IValueAccessor<DiagnosticReponse | undefined> | undefined;
	readonly chambreEspaceManoeuvre: IValueAccessor<DiagnosticReponse | undefined> | undefined;
	readonly commune: IValueAccessor<string | undefined> | undefined;
	readonly anneeRotation: IValueAccessor<number | undefined> | undefined;
	readonly bailleur: IValueAccessor<number | undefined> | undefined;
	readonly fiche: IValueAccessor<string | undefined> | undefined;
	readonly medias: IValueAccessor<ReadonlyArray<string>> | undefined;
	constructor(logement: Logement | undefined, setter: (logement: Logement) => void) {
		this.id = logement ? new ValueAccessor<number, "id", Logement>(logement, "id", setter) : undefined;
		this.creationDate = logement ? new ValueAccessor<Date, "creationDate", Logement>(logement, "creationDate", setter) : undefined;
		this.lastModificationDate = logement ? new ValueAccessor<Date, "lastModificationDate", Logement>(logement, "lastModificationDate", setter) : undefined;
		this.complet = logement ? new ValueAccessor<boolean, "complet", Logement>(logement, "complet", setter) : undefined;
		this.comment = logement ? new ValueAccessor<string | undefined, "comment", Logement>(logement, "comment", setter) : undefined;
		this.version = logement ? new ValueAccessor<number, "version", Logement>(logement, "version", setter) : undefined;
		this.nomProgramme = logement ? new ValueAccessor<string | undefined, "nomProgramme", Logement>(logement, "nomProgramme", setter) : undefined;
		this.anneeLivraison = logement ? new ValueAccessor<number | undefined, "anneeLivraison", Logement>(logement, "anneeLivraison", setter) : undefined;
		this.anneeDesignation = logement ? new ValueAccessor<number | undefined, "anneeDesignation", Logement>(logement, "anneeDesignation", setter) : undefined;
		this.rehabilitation = logement ? new ValueAccessor<boolean | undefined, "rehabilitation", Logement>(logement, "rehabilitation", setter) : undefined;
		this.logementNeuf = logement ? new ValueAccessor<boolean | undefined, "logementNeuf", Logement>(logement, "logementNeuf", setter) : undefined;
		this.etatAvancementProjet = logement ? new ValueAccessor<EtatAvancementProjet, "etatAvancementProjet", Logement>(logement, "etatAvancementProjet", setter) : undefined;
		this.suiviParArchitecte = logement ? new ValueAccessor<boolean | undefined, "suiviParArchitecte", Logement>(logement, "suiviParArchitecte", setter) : undefined;
		this.numBatiment = logement ? new ValueAccessor<string | undefined, "numBatiment", Logement>(logement, "numBatiment", setter) : undefined;
		this.numLogement = logement ? new ValueAccessor<string | undefined, "numLogement", Logement>(logement, "numLogement", setter) : undefined;
		this.numRpls = logement ? new ValueAccessor<string | undefined, "numRpls", Logement>(logement, "numRpls", setter) : undefined;
		this.reservataire = logement ? new ValueAccessor<Reservataire, "reservataire", Logement>(logement, "reservataire", setter) : undefined;
		this.financement = logement ? new ValueAccessor<Financement, "financement", Logement>(logement, "financement", setter) : undefined;
		this.adresse = logement ? new ValueAccessor<string | undefined, "adresse", Logement>(logement, "adresse", setter) : undefined;
		this.complementAdresse = logement ? new ValueAccessor<string | undefined, "complementAdresse", Logement>(logement, "complementAdresse", setter) : undefined;
		this.codePostal = logement ? new ValueAccessor<string | undefined, "codePostal", Logement>(logement, "codePostal", setter) : undefined;
		this.arrondissement = logement ? new ValueAccessor<number, "arrondissement", Logement>(logement, "arrondissement", setter) : undefined;
		this.preconisationHanditoit = logement ? new ValueAccessor<boolean | undefined, "preconisationHanditoit", Logement>(logement, "preconisationHanditoit", setter) : undefined;
		this.reglementationPMR = logement ? new ValueAccessor<boolean | undefined, "reglementationPMR", Logement>(logement, "reglementationPMR", setter) : undefined;
		this.label = logement ? new ValueAccessor<Label, "label", Logement>(logement, "label", setter) : undefined;
		this.labelAutre = logement ? new ValueAccessor<string | undefined, "labelAutre", Logement>(logement, "labelAutre", setter) : undefined;
		this.caracteristique = logement ? new ValueAccessor<Caracteristique, "caracteristique", Logement>(logement, "caracteristique", setter) : undefined;
		this.nature = logement ? new ValueAccessor<Nature, "nature", Logement>(logement, "nature", setter) : undefined;
		this.type = logement ? new ValueAccessor<TypeLogement, "type", Logement>(logement, "type", setter) : undefined;
		this.niveau = logement ? new ValueAccessor<number | undefined, "niveau", Logement>(logement, "niveau", setter) : undefined;
		this.surface = logement ? new ValueAccessor<string | undefined, "surface", Logement>(logement, "surface", setter) : undefined;
		this.chauffage = logement ? new ValueAccessor<Chauffages, "chauffage", Logement>(logement, "chauffage", setter) : undefined;
		this.jardin = logement ? new ValueAccessor<boolean, "jardin", Logement>(logement, "jardin", setter) : undefined;
		this.balcon = logement ? new ValueAccessor<boolean, "balcon", Logement>(logement, "balcon", setter) : undefined;
		this.terrasse = logement ? new ValueAccessor<boolean, "terrasse", Logement>(logement, "terrasse", setter) : undefined;
		this.loggia = logement ? new ValueAccessor<boolean, "loggia", Logement>(logement, "loggia", setter) : undefined;
		this.garage = logement ? new ValueAccessor<boolean, "garage", Logement>(logement, "garage", setter) : undefined;
		this.parking = logement ? new ValueAccessor<boolean, "parking", Logement>(logement, "parking", setter) : undefined;
		this.prixParking = logement ? new ValueAccessor<string | undefined, "prixParking", Logement>(logement, "prixParking", setter) : undefined;
		this.loyerHC = logement ? new ValueAccessor<number | undefined, "loyerHC", Logement>(logement, "loyerHC", setter) : undefined;
		this.charges = logement ? new ValueAccessor<number | undefined, "charges", Logement>(logement, "charges", setter) : undefined;
		this.chemExtConformeReglementation = logement ? new ValueAccessor<DiagnosticReponse | undefined, "chemExtConformeReglementation", Logement>(logement, "chemExtConformeReglementation", setter) : undefined;
		this.chemExtSansMarchesNiRessauts = logement ? new ValueAccessor<DiagnosticReponse | undefined, "chemExtSansMarchesNiRessauts", Logement>(logement, "chemExtSansMarchesNiRessauts", setter) : undefined;
		this.chemExtRessaut = logement ? new ValueAccessor<DiagnosticReponse | undefined, "chemExtRessaut", Logement>(logement, "chemExtRessaut", setter) : undefined;
		this.chemExtPente = logement ? new ValueAccessor<DiagnosticReponse | undefined, "chemExtPente", Logement>(logement, "chemExtPente", setter) : undefined;
		this.chemExtPalier = logement ? new ValueAccessor<DiagnosticReponse | undefined, "chemExtPalier", Logement>(logement, "chemExtPalier", setter) : undefined;
		this.chemExtRampe = logement ? new ValueAccessor<DiagnosticReponse | undefined, "chemExtRampe", Logement>(logement, "chemExtRampe", setter) : undefined;
		this.chemExtBordure = logement ? new ValueAccessor<DiagnosticReponse | undefined, "chemExtBordure", Logement>(logement, "chemExtBordure", setter) : undefined;
		this.chemExtMainCourante = logement ? new ValueAccessor<DiagnosticReponse | undefined, "chemExtMainCourante", Logement>(logement, "chemExtMainCourante", setter) : undefined;
		this.chemExtLargeurSup1m20 = logement ? new ValueAccessor<DiagnosticReponse | undefined, "chemExtLargeurSup1m20", Logement>(logement, "chemExtLargeurSup1m20", setter) : undefined;
		this.chemExtLargeurSup1m60 = logement ? new ValueAccessor<DiagnosticReponse | undefined, "chemExtLargeurSup1m60", Logement>(logement, "chemExtLargeurSup1m60", setter) : undefined;
		this.chemExtSolNonMeuble = logement ? new ValueAccessor<DiagnosticReponse | undefined, "chemExtSolNonMeuble", Logement>(logement, "chemExtSolNonMeuble", setter) : undefined;
		this.chemExtTrou = logement ? new ValueAccessor<DiagnosticReponse | undefined, "chemExtTrou", Logement>(logement, "chemExtTrou", setter) : undefined;
		this.chemExtObstacle = logement ? new ValueAccessor<DiagnosticReponse | undefined, "chemExtObstacle", Logement>(logement, "chemExtObstacle", setter) : undefined;
		this.chemExtInfo = logement ? new ValueAccessor<string | undefined, "chemExtInfo", Logement>(logement, "chemExtInfo", setter) : undefined;
		this.statioDedie = logement ? new ValueAccessor<DiagnosticReponse | undefined, "statioDedie", Logement>(logement, "statioDedie", setter) : undefined;
		this.statioProxi = logement ? new ValueAccessor<DiagnosticReponse | undefined, "statioProxi", Logement>(logement, "statioProxi", setter) : undefined;
		this.statioSousSol = logement ? new ValueAccessor<DiagnosticReponse | undefined, "statioSousSol", Logement>(logement, "statioSousSol", setter) : undefined;
		this.statioSurface = logement ? new ValueAccessor<DiagnosticReponse | undefined, "statioSurface", Logement>(logement, "statioSurface", setter) : undefined;
		this.statioVisiteur = logement ? new ValueAccessor<DiagnosticReponse | undefined, "statioVisiteur", Logement>(logement, "statioVisiteur", setter) : undefined;
		this.statioSasInt = logement ? new ValueAccessor<DiagnosticReponse | undefined, "statioSasInt", Logement>(logement, "statioSasInt", setter) : undefined;
		this.statioSasSup1m20 = logement ? new ValueAccessor<DiagnosticReponse | undefined, "statioSasSup1m20", Logement>(logement, "statioSasSup1m20", setter) : undefined;
		this.statioSasSup1m50 = logement ? new ValueAccessor<DiagnosticReponse | undefined, "statioSasSup1m50", Logement>(logement, "statioSasSup1m50", setter) : undefined;
		this.statioSasOculus = logement ? new ValueAccessor<DiagnosticReponse | undefined, "statioSasOculus", Logement>(logement, "statioSasOculus", setter) : undefined;
		this.statioInfo = logement ? new ValueAccessor<string | undefined, "statioInfo", Logement>(logement, "statioInfo", setter) : undefined;
		this.communPorteVoie = logement ? new ValueAccessor<DiagnosticReponse | undefined, "communPorteVoie", Logement>(logement, "communPorteVoie", setter) : undefined;
		this.communPorteResidence = logement ? new ValueAccessor<DiagnosticReponse | undefined, "communPorteResidence", Logement>(logement, "communPorteResidence", setter) : undefined;
		this.communPorteAuto = logement ? new ValueAccessor<DiagnosticReponse | undefined, "communPorteAuto", Logement>(logement, "communPorteAuto", setter) : undefined;
		this.communPorteTele = logement ? new ValueAccessor<DiagnosticReponse | undefined, "communPorteTele", Logement>(logement, "communPorteTele", setter) : undefined;
		this.communOuvManu = logement ? new ValueAccessor<DiagnosticReponse | undefined, "communOuvManu", Logement>(logement, "communOuvManu", setter) : undefined;
		this.communDispoHaut = logement ? new ValueAccessor<DiagnosticReponse | undefined, "communDispoHaut", Logement>(logement, "communDispoHaut", setter) : undefined;
		this.communDispoAngle = logement ? new ValueAccessor<DiagnosticReponse | undefined, "communDispoAngle", Logement>(logement, "communDispoAngle", setter) : undefined;
		this.communDispoSonore = logement ? new ValueAccessor<DiagnosticReponse | undefined, "communDispoSonore", Logement>(logement, "communDispoSonore", setter) : undefined;
		this.communDispoCamera = logement ? new ValueAccessor<DiagnosticReponse | undefined, "communDispoCamera", Logement>(logement, "communDispoCamera", setter) : undefined;
		this.communDispoSansFil = logement ? new ValueAccessor<DiagnosticReponse | undefined, "communDispoSansFil", Logement>(logement, "communDispoSansFil", setter) : undefined;
		this.communHallSup1m20 = logement ? new ValueAccessor<DiagnosticReponse | undefined, "communHallSup1m20", Logement>(logement, "communHallSup1m20", setter) : undefined;
		this.communHallSup1m50 = logement ? new ValueAccessor<DiagnosticReponse | undefined, "communHallSup1m50", Logement>(logement, "communHallSup1m50", setter) : undefined;
		this.communHallColectif = logement ? new ValueAccessor<DiagnosticReponse | undefined, "communHallColectif", Logement>(logement, "communHallColectif", setter) : undefined;
		this.communHallEclairageAuto = logement ? new ValueAccessor<DiagnosticReponse | undefined, "communHallEclairageAuto", Logement>(logement, "communHallEclairageAuto", setter) : undefined;
		this.communHallEclairage90 = logement ? new ValueAccessor<DiagnosticReponse | undefined, "communHallEclairage90", Logement>(logement, "communHallEclairage90", setter) : undefined;
		this.communHallEclairageDiode = logement ? new ValueAccessor<DiagnosticReponse | undefined, "communHallEclairageDiode", Logement>(logement, "communHallEclairageDiode", setter) : undefined;
		this.communAscenseurBouton = logement ? new ValueAccessor<DiagnosticReponse | undefined, "communAscenseurBouton", Logement>(logement, "communAscenseurBouton", setter) : undefined;
		this.communAscenseurNorme = logement ? new ValueAccessor<DiagnosticReponse | undefined, "communAscenseurNorme", Logement>(logement, "communAscenseurNorme", setter) : undefined;
		this.communAscenseurHori = logement ? new ValueAccessor<DiagnosticReponse | undefined, "communAscenseurHori", Logement>(logement, "communAscenseurHori", setter) : undefined;
		this.communInfo = logement ? new ValueAccessor<string | undefined, "communInfo", Logement>(logement, "communInfo", setter) : undefined;
		this.generalLargeurSup90 = logement ? new ValueAccessor<DiagnosticReponse | undefined, "generalLargeurSup90", Logement>(logement, "generalLargeurSup90", setter) : undefined;
		this.generalLargeurSup120 = logement ? new ValueAccessor<DiagnosticReponse | undefined, "generalLargeurSup120", Logement>(logement, "generalLargeurSup120", setter) : undefined;
		this.generalLargeurPorte90 = logement ? new ValueAccessor<DiagnosticReponse | undefined, "generalLargeurPorte90", Logement>(logement, "generalLargeurPorte90", setter) : undefined;
		this.generalPoignetPorte = logement ? new ValueAccessor<DiagnosticReponse | undefined, "generalPoignetPorte", Logement>(logement, "generalPoignetPorte", setter) : undefined;
		this.entreeLargeur = logement ? new ValueAccessor<DiagnosticReponse | undefined, "entreeLargeur", Logement>(logement, "entreeLargeur", setter) : undefined;
		this.entreeSerrure = logement ? new ValueAccessor<DiagnosticReponse | undefined, "entreeSerrure", Logement>(logement, "entreeSerrure", setter) : undefined;
		this.entreePoignee = logement ? new ValueAccessor<DiagnosticReponse | undefined, "entreePoignee", Logement>(logement, "entreePoignee", setter) : undefined;
		this.entreeAuto = logement ? new ValueAccessor<DiagnosticReponse | undefined, "entreeAuto", Logement>(logement, "entreeAuto", setter) : undefined;
		this.entreeEclairage = logement ? new ValueAccessor<DiagnosticReponse | undefined, "entreeEclairage", Logement>(logement, "entreeEclairage", setter) : undefined;
		this.dispositifHauteur130 = logement ? new ValueAccessor<DiagnosticReponse | undefined, "dispositifHauteur130", Logement>(logement, "dispositifHauteur130", setter) : undefined;
		this.dispositifHanteur90 = logement ? new ValueAccessor<DiagnosticReponse | undefined, "dispositifHanteur90", Logement>(logement, "dispositifHanteur90", setter) : undefined;
		this.dispositifAngle = logement ? new ValueAccessor<DiagnosticReponse | undefined, "dispositifAngle", Logement>(logement, "dispositifAngle", setter) : undefined;
		this.dispositifVoletElec = logement ? new ValueAccessor<DiagnosticReponse | undefined, "dispositifVoletElec", Logement>(logement, "dispositifVoletElec", setter) : undefined;
		this.dispositifManoeuvreSecours = logement ? new ValueAccessor<DiagnosticReponse | undefined, "dispositifManoeuvreSecours", Logement>(logement, "dispositifManoeuvreSecours", setter) : undefined;
		this.salleBainEspace = logement ? new ValueAccessor<DiagnosticReponse | undefined, "salleBainEspace", Logement>(logement, "salleBainEspace", setter) : undefined;
		this.wcEspace = logement ? new ValueAccessor<DiagnosticReponse | undefined, "wcEspace", Logement>(logement, "wcEspace", setter) : undefined;
		this.wcCloison = logement ? new ValueAccessor<DiagnosticReponse | undefined, "wcCloison", Logement>(logement, "wcCloison", setter) : undefined;
		this.wcHauteur = logement ? new ValueAccessor<DiagnosticReponse | undefined, "wcHauteur", Logement>(logement, "wcHauteur", setter) : undefined;
		this.wcSuspendu = logement ? new ValueAccessor<DiagnosticReponse | undefined, "wcSuspendu", Logement>(logement, "wcSuspendu", setter) : undefined;
		this.doucheItalienne = logement ? new ValueAccessor<DiagnosticReponse | undefined, "doucheItalienne", Logement>(logement, "doucheItalienne", setter) : undefined;
		this.doucheItalienneOk = logement ? new ValueAccessor<DiagnosticReponse | undefined, "doucheItalienneOk", Logement>(logement, "doucheItalienneOk", setter) : undefined;
		this.doucheReceveurEncastre = logement ? new ValueAccessor<DiagnosticReponse | undefined, "doucheReceveurEncastre", Logement>(logement, "doucheReceveurEncastre", setter) : undefined;
		this.doucheReceveurEncastreOk = logement ? new ValueAccessor<DiagnosticReponse | undefined, "doucheReceveurEncastreOk", Logement>(logement, "doucheReceveurEncastreOk", setter) : undefined;
		this.doucheParois = logement ? new ValueAccessor<DiagnosticReponse | undefined, "doucheParois", Logement>(logement, "doucheParois", setter) : undefined;
		this.lavaboAdapte = logement ? new ValueAccessor<DiagnosticReponse | undefined, "lavaboAdapte", Logement>(logement, "lavaboAdapte", setter) : undefined;
		this.lavaboLibreSous = logement ? new ValueAccessor<DiagnosticReponse | undefined, "lavaboLibreSous", Logement>(logement, "lavaboLibreSous", setter) : undefined;
		this.lavaboProfondeur = logement ? new ValueAccessor<DiagnosticReponse | undefined, "lavaboProfondeur", Logement>(logement, "lavaboProfondeur", setter) : undefined;
		this.lavaboHauteur = logement ? new ValueAccessor<DiagnosticReponse | undefined, "lavaboHauteur", Logement>(logement, "lavaboHauteur", setter) : undefined;
		this.lavaboHauteurReglable = logement ? new ValueAccessor<DiagnosticReponse | undefined, "lavaboHauteurReglable", Logement>(logement, "lavaboHauteurReglable", setter) : undefined;
		this.lavaboSiphon = logement ? new ValueAccessor<DiagnosticReponse | undefined, "lavaboSiphon", Logement>(logement, "lavaboSiphon", setter) : undefined;
		this.chambreDiametre = logement ? new ValueAccessor<DiagnosticReponse | undefined, "chambreDiametre", Logement>(logement, "chambreDiametre", setter) : undefined;
		this.chambrePassage = logement ? new ValueAccessor<DiagnosticReponse | undefined, "chambrePassage", Logement>(logement, "chambrePassage", setter) : undefined;
		this.cuisinePassage = logement ? new ValueAccessor<DiagnosticReponse | undefined, "cuisinePassage", Logement>(logement, "cuisinePassage", setter) : undefined;
		this.cuisineEspace = logement ? new ValueAccessor<DiagnosticReponse | undefined, "cuisineEspace", Logement>(logement, "cuisineEspace", setter) : undefined;
		this.cuisineProfondeur = logement ? new ValueAccessor<DiagnosticReponse | undefined, "cuisineProfondeur", Logement>(logement, "cuisineProfondeur", setter) : undefined;
		this.cuisineSiphon = logement ? new ValueAccessor<DiagnosticReponse | undefined, "cuisineSiphon", Logement>(logement, "cuisineSiphon", setter) : undefined;
		this.cuisineCuve = logement ? new ValueAccessor<DiagnosticReponse | undefined, "cuisineCuve", Logement>(logement, "cuisineCuve", setter) : undefined;
		this.terrasseAcces = logement ? new ValueAccessor<DiagnosticReponse | undefined, "terrasseAcces", Logement>(logement, "terrasseAcces", setter) : undefined;
		this.terrasseRessaut = logement ? new ValueAccessor<DiagnosticReponse | undefined, "terrasseRessaut", Logement>(logement, "terrasseRessaut", setter) : undefined;
		this.logementInfo = logement ? new ValueAccessor<string | undefined, "logementInfo", Logement>(logement, "logementInfo", setter) : undefined;
		this.communBoiteLettre = logement ? new ValueAccessor<DiagnosticReponse | undefined, "communBoiteLettre", Logement>(logement, "communBoiteLettre", setter) : undefined;
		this.entreeEspaceManoeuvre = logement ? new ValueAccessor<DiagnosticReponse | undefined, "entreeEspaceManoeuvre", Logement>(logement, "entreeEspaceManoeuvre", setter) : undefined;
		this.generalLargeurPorte80 = logement ? new ValueAccessor<DiagnosticReponse | undefined, "generalLargeurPorte80", Logement>(logement, "generalLargeurPorte80", setter) : undefined;
		this.generalEspaceManoeuvre = logement ? new ValueAccessor<DiagnosticReponse | undefined, "generalEspaceManoeuvre", Logement>(logement, "generalEspaceManoeuvre", setter) : undefined;
		this.wcBarreAppui = logement ? new ValueAccessor<DiagnosticReponse | undefined, "wcBarreAppui", Logement>(logement, "wcBarreAppui", setter) : undefined;
		this.wcLargeurPorte80 = logement ? new ValueAccessor<DiagnosticReponse | undefined, "wcLargeurPorte80", Logement>(logement, "wcLargeurPorte80", setter) : undefined;
		this.wcLargeurPorte90 = logement ? new ValueAccessor<DiagnosticReponse | undefined, "wcLargeurPorte90", Logement>(logement, "wcLargeurPorte90", setter) : undefined;
		this.wcPoignetPorte = logement ? new ValueAccessor<DiagnosticReponse | undefined, "wcPoignetPorte", Logement>(logement, "wcPoignetPorte", setter) : undefined;
		this.wcEspaceManoeuvre = logement ? new ValueAccessor<DiagnosticReponse | undefined, "wcEspaceManoeuvre", Logement>(logement, "wcEspaceManoeuvre", setter) : undefined;
		this.bainLargeurPorte80 = logement ? new ValueAccessor<DiagnosticReponse | undefined, "bainLargeurPorte80", Logement>(logement, "bainLargeurPorte80", setter) : undefined;
		this.bainLargeurPorte90 = logement ? new ValueAccessor<DiagnosticReponse | undefined, "bainLargeurPorte90", Logement>(logement, "bainLargeurPorte90", setter) : undefined;
		this.bainPoignetPorte = logement ? new ValueAccessor<DiagnosticReponse | undefined, "bainPoignetPorte", Logement>(logement, "bainPoignetPorte", setter) : undefined;
		this.bainEspaceManoeuvre = logement ? new ValueAccessor<DiagnosticReponse | undefined, "bainEspaceManoeuvre", Logement>(logement, "bainEspaceManoeuvre", setter) : undefined;
		this.doucheBarreAppui = logement ? new ValueAccessor<DiagnosticReponse | undefined, "doucheBarreAppui", Logement>(logement, "doucheBarreAppui", setter) : undefined;
		this.doucheSiege = logement ? new ValueAccessor<DiagnosticReponse | undefined, "doucheSiege", Logement>(logement, "doucheSiege", setter) : undefined;
		this.doucheCloisons = logement ? new ValueAccessor<DiagnosticReponse | undefined, "doucheCloisons", Logement>(logement, "doucheCloisons", setter) : undefined;
		this.chambreLargeurPorte80 = logement ? new ValueAccessor<DiagnosticReponse | undefined, "chambreLargeurPorte80", Logement>(logement, "chambreLargeurPorte80", setter) : undefined;
		this.chambreLargeurPorte90 = logement ? new ValueAccessor<DiagnosticReponse | undefined, "chambreLargeurPorte90", Logement>(logement, "chambreLargeurPorte90", setter) : undefined;
		this.chambrePoignetPorte = logement ? new ValueAccessor<DiagnosticReponse | undefined, "chambrePoignetPorte", Logement>(logement, "chambrePoignetPorte", setter) : undefined;
		this.chambreEspaceManoeuvre = logement ? new ValueAccessor<DiagnosticReponse | undefined, "chambreEspaceManoeuvre", Logement>(logement, "chambreEspaceManoeuvre", setter) : undefined;
		this.commune = logement ? new ValueAccessor<string | undefined, "commune", Logement>(logement, "commune", setter) : undefined;
		this.anneeRotation = logement ? new ValueAccessor<number | undefined, "anneeRotation", Logement>(logement, "anneeRotation", setter) : undefined;
		this.bailleur = logement ? new ValueAccessor<number | undefined, "bailleur", Logement>(logement, "bailleur", setter) : undefined;
		this.fiche = logement ? new ValueAccessor<string | undefined, "fiche", Logement>(logement, "fiche", setter) : undefined;
		this.medias = logement ? new ValueAccessor<ReadonlyArray<string>, "medias", Logement>(logement, "medias", setter) : undefined;
	}
}
